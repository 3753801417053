import { FormGroup, Input, Label } from "reactstrap";

export default function WeeksCheck({setWeek, weeks, _key}) {
    return (
        <FormGroup className="my-4" check>
            <Label check className="mx-1 mt-3">
                Domingo
                <Input
                    type="checkbox"
                    name={_key+"-week0"}
                    checked={weeks.week0}
                    onClick={() => setWeek(_key, "week0", !weeks.week0)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>
        
            <Label check className="mx-1 mt-3">
                Segunda-feira
                <Input
                    type="checkbox"
                    name={_key+"-week1"}
                    checked={weeks.week1}
                    onClick={() => setWeek(_key, "week1", !weeks.week1)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>
            <Label check className="mx-1 mt-3">
                Terça-feira
                <Input
                    type="checkbox"
                    name={_key+"-week2"}
                    checked={weeks.week2}
                    onClick={() => setWeek(_key, "week2", !weeks.week2)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>
            <Label check className="mx-1 mt-3">
                Quarta-feira
                <Input
                    type="checkbox"
                    name={_key+"-week3"}
                    checked={weeks.week3}
                    onClick={() => setWeek(_key, "week3", !weeks.week3)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>
            <Label check className="mx-1 mt-3">
                Quinta-feira
                <Input
                    type="checkbox"
                    name={_key+"-week4"}
                    checked={weeks.week4}
                    onClick={() => setWeek(_key, "week4", !weeks.week4)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>
            <Label check className="mx-1 mt-3">
                Sexta-feira
                <Input
                    type="checkbox"
                    name={_key+"-week5"}
                    checked={weeks.week5}
                    onClick={() => setWeek(_key, "week5",!weeks.week5)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>
            <Label check className="mx-1 mt-3">
                Sábado
                <Input
                    type="checkbox"
                    name={_key+"-week6"}
                    checked={weeks.week6}
                    onClick={() => setWeek(_key, "week6", !weeks.week6)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>
        </FormGroup>
    )
}