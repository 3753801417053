import { FormGroup, Input, Label } from "reactstrap";

export default function MonthsCheck({setMonth, months, _key}) {
    return (
        <FormGroup className="my-4" check>

            <Label check className="mx-1 mt-3">
                JAN
                <Input
                    type="checkbox"
                    name={_key+"-jan"}
                    checked={months.jan}
                    onClick={() => setMonth(_key, "jan", !months.jan)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                FEV
                <Input
                    type="checkbox"
                    name={_key+"-fev"}
                    checked={months.fev}
                    onClick={() => setMonth(_key, "fev", !months.fev)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                MAR
                <Input
                    type="checkbox"
                    name={_key+"-mar"}
                    checked={months.mar}
                    onClick={() => setMonth(_key, "mar", !months.mar)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                ABR
                <Input
                    type="checkbox"
                    name={_key+"-abr"}
                    checked={months.abr}
                    onClick={() => setMonth(_key, "abr", !months.abr)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                MAI
                <Input
                    type="checkbox"
                    name={_key+"-mai"}
                    checked={months.mai}
                    onClick={() => setMonth(_key, "mai", !months.mai)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                JUN
                <Input
                    type="checkbox"
                    name={_key+"-jun"}
                    checked={months.jun}
                    onClick={() => setMonth(_key, "jun", !months.jun)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                JUL
                <Input
                    type="checkbox"
                    name={_key+"-jul"}
                    checked={months.jul}
                    onClick={() => setMonth(_key, "jul", !months.jul)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                AGO
                <Input
                    type="checkbox"
                    name={_key+"-ago"}
                    checked={months.ago}
                    onClick={() => setMonth(_key, "ago", !months.ago)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                SET
                <Input
                    type="checkbox"
                    name={_key+"-set"}
                    checked={months.set}
                    onClick={() => setMonth(_key, "set", !months.set)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                OUT
                <Input
                    type="checkbox"
                    name={_key+"-out"}
                    checked={months.out}
                    onClick={() => setMonth(_key, "out", !months.out)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                NOV
                <Input
                    type="checkbox"
                    name={_key+"-nov"}
                    checked={months.nov}
                    onClick={() => setMonth(_key, "nov", !months.nov)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>


            <Label check className="mx-1 mt-3">
                DEZ
                <Input
                    type="checkbox"
                    name={_key+"-dez"}
                    checked={months.dez}
                    onClick={() => setMonth(_key, "dez", !months.dez)}
                    />
                    <span className="form-check-sign">
                    </span>
            </Label>
        </FormGroup>
    )
}