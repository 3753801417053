import { AiOutlineCopy, AiOutlineShareAlt } from "react-icons/ai";
import { NewCode, QRBlock, QRCodeImage, QRCodeImageBox, QrCodeCopy, QrCodeRestart, QrDesc, ShareQrBlock, ShareQrCode } from "./style";
import { IoReload } from "react-icons/io5";
import QRCoder from "react-qr-code";
import { useContext, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { Tooltip } from "reactstrap";
import Api from "services/api";

export default function QRCode({src, status, getWhatsapp}){
    const [authData] = useContext(AuthContext);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);



    const newQr =  async () => {
        try {
            await Api("get", "whatsapp/new", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
        } catch (error) {
            console.log("error:",error);
        }
    }

    const ShareThisQr = async () => {
        const shareData = {
            title: 'Compartilhar',
            text: 'Escanear Qr Code!',
            url: window.location.origin + "/whatsapp-qr/" + authData.auth.id
        }

        await navigator.share(shareData);

    }
    return (
        <QRBlock>
            <QrDesc>
                {
                    status === "notLogged" ?
                        <>
                            <h4>1. Abra o WhatsApp no seu celular.</h4>
                            <h4>2. Toque em <strong>Mais opções</strong> ou <strong>Configurações</strong> e selecione<strong> Aparelhos Conectados.</strong></h4>
                            <h4>3. Aponte seu celular para esta tela para capturar o código.</h4>
                        </>
                    : 
                        <>
                            <h2>Código Expirado</h2>
                        </>
                }
            </QrDesc>
            <div style={{width:"100%", display: "flex", justifyContent: "center", margin: "12px"}}>
                <NewCode onClick={() => newQr()}>Gerar Novo QrCode</NewCode> 
            </div>
            <QRCodeImageBox>
                {
                    status === "notLogged" ?
                    <QRCoder value={src}/> : null
                    
                }
                <ShareQrBlock className="w-100 d-flex align-items-center justify-content-center mt-5">
                    <ShareQrCode onClick={() => ShareThisQr()}><span className="mr-3"><AiOutlineShareAlt/><span className="ml-3">Compartilhar</span></span></ShareQrCode> 
                    <QrCodeCopy onClick={()=>{
                        navigator.clipboard.writeText(window.location.origin + "/whatsapp-qr/" + authData.auth.id);
                    }} id="copy-qr-code-link" className="ml-3"><AiOutlineCopy/></QrCodeCopy>
                        <Tooltip
                            placement={"top"}
                            isOpen={tooltipOpen}
                            target={'copy-qr-code-link'}
                            toggle={toggle}
                        >
                        Copiar link
                    </Tooltip>
                </ShareQrBlock>
            </QRCodeImageBox>

        </QRBlock>
    )
}