import styled from "styled-components";


export const ProgressBar = styled.div`
    width: 100%;
    padding: 15px;
`;

export const Progress = styled.div`
    width: ${p => p.progress}%;
    height: 5px;
    background-color: ${ p => p.duplicated ? "yellow" : "green"};
`;

export const WarningTitle = styled.div`
    a {
        color: #e14eca!important;
        text-decoration: underline!important;
        cursor: pointer;
    }
`;
export const SucesssTitle = styled.div`
    a {
        color: #e14eca!important;
        text-decoration: underline!important;
        cursor: pointer;
    }
`;