import axios from "axios";
import vars from "./constants.json"
const url = vars.WSSERVER;

export default async function Api(method, path, data, config = {}){
    const methods = {
        post: axios.post,
        get: axios.get,
        put: axios.put,
        delete: axios.delete
    }
    let res = false;
    let hasErr = false;
    let err = false;
    try {
        if(method==="get") {
            res = await methods[method](url+"/"+path, data)
        }
        if(!res) {
            res = await methods[method](url+"/"+path, data, config)
        }
    } catch (error) {
        hasErr = true;
        err = error;
        console.log("error.response:",error.response);
        // if(error.response && error.response.data.status && error.response.data.status === "needLogin" && path != "auth/valid"){
        //     localStorage.removeItem("terces");
        //     window.location = "/login"
        // }
    }
    if(hasErr){
        throw new Error(err);
    }

    return res;
    
}