import Auth from "components/Auth";
import { AuthContext } from "contexts/AuthContext";
import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Api from "services/api";
import { get_relatory_url } from "utils/get_relatory_url";

function AuthLayout(props){
    const [isAuth, setIsAuth] = useState("waiting");
    const [authData, setAuthData] = useContext(AuthContext);
    
    const validJwt = async () => {
        const jwt = localStorage.getItem("terces")
        if(!jwt) {
            setIsAuth(false)
            return
        } 
        try {
            const res = await Api("get", "auth/valid", {
                headers: {
                    "x-access-token": jwt
                }
            });
            if(res.data.id){
                setAuthData({
                    ...authData,
                    auth: res.data
                })
                setIsAuth(true)
            } else {
                setIsAuth(false)
            }
        } catch (error) {
            console.log("error:",error);
        }
    }

    useEffect(()=>{
        if(isAuth=="waiting"){
            validJwt();
        }
    },[isAuth])

 
    if(isAuth != "waiting" && isAuth) {
        let _admin = false;
        authData.roles.map(i=>{
            if(i=="ROLE_ADMIN"){
                _admin = true;
            }
        })
        
        if(window.location.hostname.includes(get_relatory_url())) {
            if(_admin) {
                return  <Redirect to={"/relatorios"}/>
            }
        }
        // return  <Redirect to={"/admin"}/>
    }

    return (
        <>
           <Auth path={props.router}/>
        </>
    )
}

export default AuthLayout;