import { AuthContext } from "contexts/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import ReactNotificationAlert from "react-notification-alert";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Api from "services/api";

export default function ConfigAdm(){
    const notificationAlertRef = useRef(null);
    const [auth, setAuth] = useContext(AuthContext);
    const [config, setConfig] = useState({
        sendMsgInterval: "",
        canread: false,
        msgmodels: [],
        global: "",
        wpp: "",
        status: false
    })

    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });

    const saveConfig = async () => {
        try {
            const res = await Api("post", "config/set", {
                sendMsgInterval: config.sendMsgInterval,
                canread: config.canread,
                wpp: config.wpp,
                msgmodels: config.msgmodels
            }, 
            {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                getConfig();
                notify("success", "Configurações salva com sucesso!");
            }
        } catch (error) {
            console.log("error:",error);
        }    
    }

    const getConfig = async () => {
        try {
            const res = await Api("get", "config/get", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            setAuth({
                ...auth,
                config: res.data
            })
            setConfig({...config,
                status: true, 
                ...res.data
            })
        } catch (error) {
            console.log("error:",error);
        }    
    }

    const restartSystem = async () => {
        try {
            const res = await Api("get", "system/restart", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                notify("success", "Bot whatsapp reiniciado com sucesso!")
            }
        } catch (error) {
            console.log("error:",error);
        }    
    }

    const resetSystem = async () => {
        try {
            const res = await Api("get", "system/reset", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                notify("success", "Servidor reiniciado com sucesso!")
            }
        } catch (error) {
            console.log("error:",error);
        }    
    }

    const toggle = () => setModal({...modal, status: !modal.status});

    
    const notify = (type, text) => {
       
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };


    const onChange = async (value, name) => {
        if(name=="sendMsgInterval") {
            value = value.replace(/[^\d]/g, "")
        }
        setConfig({...config, [name]: value})
    }

   
    useEffect(()=>{
        if(!config.status){
            getConfig();
        }
    },[config])

    return (
        <div className="content">
             <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={()=>{
                        modal.onOk(modal.id);
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            <ReactNotificationAlert ref={notificationAlertRef} />
            <Row>
                <Col md="6">
                    <Card>
                        <CardHeader>
                            <h3>Configurações</h3>
                        </CardHeader>
                        <CardBody>
                            <Button className="mb-5" color="primary" onClick={()=>{
                                setModal({
                                    status: true,
                                    text: "Deseja reiniciar o sistema?",
                                    buttonOk: "Reiniciar",
                                    onOk: restartSystem
                                });
                            }} >Reiniciar Bot Whatsapp</Button>
                            {/* <Button className="mb-5" color="primary" onClick={()=>{
                                setModal({
                                    status: true,
                                    text: "Deseja reiniciar o servidor?",
                                    buttonOk: "Reiniciar",
                                    onOk: resetSystem
                                });
                            }} >Reiniciar Servidor</Button> */}
                            <FormGroup>
                                <Label>Intervalo entre cada mensagem (em segundos)</Label>
                                <Input type="text" value={config.sendMsgInterval} onChange={(e)=>onChange(e.target.value, "sendMsgInterval")} placeholder="0"/>
                            </FormGroup>
                             
                            <FormGroup className="mt-3" check>
                                <Label check>Ler arquivos no servidor
                              
                                <Input
                                    type="checkbox"
                                    name="check"
                                    checked={config.canread}
                                    onClick={() => {
                                        setConfig({...config, canread: !config.canread});
                                    }}
                                    />
                                    <span className="form-check-sign">
                                    </span>
                                </Label>
                            </FormGroup>
                             
                            <FormGroup className="mt-3">
                                <Label>Número de whatsapp:</Label>
                                <Input
                                    type="text"
                                    value={config.wpp}
                                    color="primary"
                                    className="w-50"
                                    name="wpp"
                                    onChange={(e) => {
                                        setConfig({...config, wpp: e.target.value});
                                    }}
                                />
                            </FormGroup>
                        </CardBody>
                        <CardFooter>
                            <span>
                                <Button color="primary" onClick={()=>saveConfig()}>Salvar</Button>
                            </span>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}