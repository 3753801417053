import { LoadingBox, LoadingIcon, LoadingWrapper, Text } from "./style";


export default function Loading({text}){
    return (
        <LoadingWrapper>
            <LoadingBox>
                <LoadingIcon class="lds-ring"><div></div><div></div><div></div><div></div></LoadingIcon>
                <Text>{text}</Text>
            </LoadingBox>
        </LoadingWrapper>
    )
}