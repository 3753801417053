import { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Api from "services/api";
import {AiFillDelete} from "react-icons/ai"
import styled from "styled-components";
import * as XLSX from "xlsx";
import ReactNotificationAlert from "react-notification-alert";
import { VscDebugPause, VscDebugStart } from "react-icons/vsc";

const statusTypes = {
    completed: {
        text: "Finalizado",
        color: "green"
    },
    waiting: {
        text: "Iniciando",
        color: "purple"
    },
    paused: {
        text: "Pausado",
        color: "#b53402",
    },
    sending: {
        text: "Enviando mensagens",
        color: "orange"
    },
}
export default function MensagensPage(){
    const notificationAlertRef = useRef(null);
    const [mensagens, setMensagens] = useState();
    const [relatorio, setRelatorio] = useState(false);
    const [mensagem, setMensagem] = useState(false);
    const [modal, setModal] = useState({
        status: false,
        id: "",
        text: "",
        buttonOk: "",
        onOk: false
    });

    const notify = (text, type) => {
       
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };


   
    const startOrStopMsg = async (id) => {
        try {
            await Api("post", "send/start-or-stop-msg", {id}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            getMensagens();
        } catch (error) {
            console.log("error:",error);
        }
    }

    const getMensagens = async () => {
        try {
            const res = await Api("get", "send/mensagens", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            setMensagens(res.data);
        } catch (error) {
            console.log("error:",error);
        }
    }

    const downloadXLS = () => {
        const workbook = XLSX.utils.book_new();
        let date = new Date();
        const hoje = date.toLocaleDateString();
        date.setDate(date.getDate() - 1);
        const ontem = date.toLocaleDateString();
        
        let cols =  mensagem.colunas;
        
        cols.push('Data');
        cols.push('Data 2');

        let rows = mensagem.useless;
        for (let i = 0; i < rows.length; i++) {
            rows[i].push(hoje)
            rows[i].push(ontem)
        }
        var ws_data = [
            cols,
            ...rows
        ];
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
        XLSX.writeFile(workbook, mensagem.name+".xlsx");
    }

    const getMensagem = async (id) => {
        console.log("getMensagem:",id);
        try {
            const res = await Api("get", "send/status?id="+id, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            console.log("getMensagem:",res.data);
            setMensagem({...res.data});
        } catch (error) {
            console.log("error:",error);
        }
    }

    function percentage(partialValue, totalValue) {
        return parseInt((100 * parseInt(partialValue)) / parseInt(totalValue));
    }



    const deleteMsg = async (id) => {
        try {
            const res = await Api("post", "send/delete-msg", {
                id,
            }, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data.error){
                return notify(res.data.msg, "danger")
            }
            notify("Mensagem Deletada com Sucesso!", "success");
            getMensagens();
        } catch (error) {
            console.log("error:",error);
        }
    }

    const setMsgInterval = async (value, id) => {
        try {
            const res = await Api("post", "send/update-interval", {
                id,
                interval: value
            }, 
            {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data.error){
                return notify(res.data.msg, "danger")
            }
            notify("Intervalo atualizado com sucesso!", "success");
            getMensagens();
        } catch (error) {
            console.log("error:",error);
        }
    }

    const isStart = (obj) => {
        if(!obj.hasOwnProperty("start")) return false;
        return obj.start
    }

    const toggle = () => setModal({...modal, status: !modal.status});


    useEffect(()=>{
        if(!mensagens){
            getMensagens();
        }
    },[mensagens])

    return (
        <div className="content">
            <ReactNotificationAlert ref={notificationAlertRef} />
            <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={()=>{
                        modal.onOk(modal.id);
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
                
            <Row>
                <Col md="7">
                    <Card>
                        <CardHeader>
                            <h3>Mensagens</h3>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead className="text-primary">
                                    <tr>
                                       <th>Nome</th>
                                       <th>Status</th>
                                       <th>Intervalo</th>
                                       <th></th>
                                       <th></th>
                                       <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        mensagens &&
                                        mensagens.map(i => (
                                            <tr key={i._id}>
                                                <td>{i.name}</td>
                                                <td><span style={{background: statusTypes[i.status].color, padding: "4px 8px", borderRadius: 3}}>{statusTypes[i.status].text}</span></td>
                                                <td>
                                                    {
                                                        i.status != 'completed' ?
                                                            <input style={{width: 90}} value={i.sendMsgInterval} onChange={ (e) => setMsgInterval(e.target.value, i._id) } />
                                                        : '...'
                                                    }
                                                </td>
                                                <td><Button size="sm" color="success" onClick={()=>getMensagem(i._id)}>Ver Mensagem</Button></td>
                                                <td>
                                                    <span style={{cursor: "pointer"}} onClick={()=>{
                                                        setModal({
                                                            status: true,
                                                            msgStatus: isStart(i),
                                                            text: isStart(i) ? "Deseja pausar o envio desta mensagens?" : "Deseja retomar o envio desta mensagens?",
                                                            buttonOk: isStart(i) ? "Pausar" : "Iniciar envio",
                                                            id: i._id,
                                                            onOk: startOrStopMsg
                                                        })
                                                        }}> {i.start ? <VscDebugPause style={{fontSize: 18, color: "#d78914"}}/> : <VscDebugStart style={{fontSize: 18, color: "#2edd84"}}/>}</span>
                                                </td>
                                                <td><span style={{cursor: "pointer"}} onClick={()=>{
                                                    setModal({
                                                        status: true,
                                                        text: "Deseja Deletar esta Mensagem?",
                                                        buttonOk: "Deletar",
                                                        id: i._id,
                                                        onOk: deleteMsg
                                                    })
                                                    }}><AiFillDelete style={{fontSize: 18, color: "#ce56c7"}}/></span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                            {
                                mensagem &&
                                <>
                                    <Col>
                                        <h4>Mensagem: {mensagem.name}</h4>
                                    </Col>
                                    <Col>
                                        <h4>Data: {mensagem.date}</h4>
                                    </Col>
                                </>
                            }
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {
                                mensagem &&
                                <>
                                    <Row className="my-3">
                                        <Col><p>Enviando: {mensagem.finish}/{mensagem.length}</p></Col>
                                        <Col><p>Sucesso: {mensagem.finish - mensagem.errors}</p></Col>
                                        <Col><p>Sem whatsapp: {mensagem.errors}</p></Col>
                                    </Row>
                                    <p>{percentage(mensagem.finish, mensagem.length)}%</p>
                                    <ProgressBox>
                                        <ProgressBar percentage={percentage(mensagem.finish, mensagem.length)}/>
                                    </ProgressBox>
                                    
                                </>
                            }
                        </CardBody>
                        <CardFooter>
                            {
                                mensagem.status == "completed" &&
                                <span>
                                    <Button style={{marginTop: 24}} onClick={()=>downloadXLS()} className="btn-fill" color="primary">Gerar Arquivo com Contatos Sem Whatsapp</Button>
                                </span>
                            }
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const ProgressBar = styled.div`
    width: ${p => p.percentage}%;
    height: 8px;
    background-color: green;
`;

const ProgressBox = styled.div`

`;