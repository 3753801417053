import FacebookTextEditor from "components/FacebookTextEditor";
import { FileSelector } from "components/FacebookTextEditor/style";
import { AuthContext } from "contexts/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { VscDebugPause, VscDebugStart } from "react-icons/vsc";
import ReactNotificationAlert from "react-notification-alert";
import { ToastContainer, toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Api from "services/api";
import server_url from "services/constants.json";
let lastConfig = {};
let texttime = 0; 
const fields_pattern = {
    method: "list",
    group: "",
    time: {
        min: "",
        max: ""
    },
    text: "",
    weeks: {
        week0: false,
        week1: false,
        week2: false,
        week3: false,
        week4: false,
        week5: false,
        week6: false
    }
}

const configModel = {
    pauseFacebook: false
}


function FacebookPost(){
    const notificationAlertRef = useRef(null);
    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });
    const [auth, setAuth] = useContext(AuthContext);

    const [config, setConfig] = useState({
        pauseFacebook: false
    })

    const [drag, setDrag] = useState(false);

    const [textFormat, setTextFormat] = useState("");

    const [posts, setPosts] = useState({
        queried: false,
        list: []
    });

    const [fields, setFields] = useState(fields_pattern);
    const [img, setImg] = useState({
        has: false,
        type: "",
        file: "",
        origin: "local"
    })
    const toggle = () => setModal({...modal, status: !modal.status});

    const notify = (type, text) => {
       
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const uploadFile = async(media) => {
        let filename = false;
        try {
            var formdata = new FormData();
            const fileSize = media.size;
            formdata.append("media",media.file)
            const res = await Api("post", "send/upload", formdata, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                },
                // onUploadProgress: progressEvent => {
                //     setSending({status: true, sended: false, name: i.media.file.name, type: geType(i.type), percentage: percentage(progressEvent.loaded, progressEvent.loaded >= fileSize ? progressEvent.loaded : fileSize)})
                // }
            });
            if(res.data && res.data.file && res.data.file.size) {
                filename = res.data.file.filename
            }
        } catch (error) {
            
        }
        return filename
    }
    const createPost = async () => {
        try {
            const fileupload = img.has && img.origin == "local" ? await uploadFile(img) : false;
            let filename = fileupload ? fileupload : "";
            if(fileupload && img.origin == "server") {
                filename = img.file
            }
            const res  = await Api("post", "facebook/create-post", {
                ...fields,
                img: filename
            },{
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            console.log("res:",res);
            setFields(fields_pattern)
            getPost();
        } catch (error) {
            console.log("error on createPost:",error);
        }
    }

    const editPost = async () => {
        console.log("current img:",img);
        try {
            const fileupload = img.has && img.origin == "local" ? await uploadFile(img) : false;
            let filename = fileupload ? fileupload : "";
            if(!fileupload && img.origin == "server") {
                filename = img.file
            }
            console.log("filename>",filename);
            const res  = await Api("post", "facebook/update-post", {
                ...fields,
                img: filename
            },{
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            notify("success", "Postagem editada com sucesso!");
            getPost();
        } catch (error) {
            console.log("error on editPost:",error);
        }
    }

    const deletePost = async (id) => {
        try {
            const res  = await Api("post", "facebook/delete-post", {
                id: id
            },{
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            notify("success", "Postagem deletada com sucesso!");
            getPost();
        } catch (error) {
            console.log("error on createPost:",error);
        }
    }

    const getPost = async () => {
        try {
            const res  = await Api("get", "facebook/get-post", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                setPosts({list: res.data, queried: true, method: "list"});
            } else {
                setPosts({...posts, queried: true, method: "list"});
            }
        } catch (error) {
            setPosts({...posts, queried: true, method: "list"});
        }
    }

    const startOrStopPost = async (id,status) => {
        try {
            await Api("post", "facebook/pause-post", {
                id,
                pause: !status
            },
            {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            getPost();
        } catch (error) {
            
        }
    }

    const formatUnicode = async () => {
        try {
            const res = await Api("post", "facebook/text-format", {
                text: fields.text
            },
            {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                console.log("formatUnicode res.data:",res.data);
                setTextFormat(res.data)
            }
        } catch (error) {
            
        }
    }

    const onChangeFile = (file) => {
        setImg({
            has: true,
            type: file.type.split("/")[0],
            file: file,
            origin: "local"
        });
    }

    const tener = (num) => {
        return num < 10 ? `0${num}` : `${num}`
    }
    
    const getLastsend = (last) => {
        if(last.year === 0 ) return "Não há envios!"
        return `${tener(last.day)}/${tener(last.month+1)}/${last.year} ${tener(last.hour)}:${tener(last.minutes)}`;
    }

    const onChange = (value, name) => {
        setFields({...fields, [name]: value});
    }

    const saveConfig = async () => {
      
        const toast_id = toast.loading("Salvando configurações...")
        try {
            const res = await Api("post", "config/set", {pauseFacebook: config.pauseFacebook}, 
            {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                toast.update(toast_id, { render: "Configurações salva com sucesso!", type: "success", isLoading: false, autoClose: 5000});
            }
        } catch (error) {
            toast.update(toast_id, { render: "Erro ao salvar as configurações", type: "danger", isLoading: false, autoClose: 5000});
            console.log("error:",error);
        }    
    }

    const parseResultToModel = (data) => {
        lastConfig = data;
        let parsed = {}
        for (const key in configModel) {
            if(data.hasOwnProperty(key)) {
                if(key=="coordinates") {
                    lastConfig[key] = data[key].toString();
                    parsed[key] = data[key].toString();
                    continue
                }
                parsed[key] = data[key]
            }
        }
        setConfig({...config,
            status: true, 
            ...parsed
        })
    }

    const getConfig = async () => {
        try {
            const res = await Api("get", "config/get", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            setAuth({
                ...auth,
                config: res.data
            })
            parseResultToModel(res.data);
        } catch (error) {
            console.log("error:",error);
        }    
    }

    useEffect(()=>{
        if(!config.status){
            getConfig();
        }
    },[config])

    useEffect(()=>{
        if(!posts.queried) {
            getPost();
        }
    },[posts]);

    useEffect(()=>{
        formatUnicode();
    },[fields.text]);


    return (
        <div className="content">
            <ToastContainer/>
            <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={()=>{
                        modal.onOk(modal.id, modal.msgStatus);
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            
            <ReactNotificationAlert ref={notificationAlertRef} />
           
            <Row>
                {
                    fields.method === "list" ?
                    <Col md="12">
                        <Card style={{padding: 12}}>
                            <CardHeader>
                                <Row>
                                    <Col><h2>Lista de Postagens</h2></Col>
                                    <Col><Button className="float-right" color="primary" onClick={()=> setFields({...fields, method: "create"})}>Criar postagem</Button></Col>
                                </Row>
                                <Row>
                                    <Col className="col-4">
                                        <Row>
                                            <Col className="mb-4 col-8">
                                                <FormGroup check>
                                                    <Label check className="mx-1 mt-3">
                                                        Pausar o envio de mensagens
                                                        <Input
                                                            type="checkbox"
                                                            name="pauseMsgSend"
                                                            checked={config.pauseFacebook}
                                                            onClick={() => {
                                                                setConfig({...config, pauseFacebook: !config.pauseFacebook});
                                                            }}
                                                            />
                                                            <span className="form-check-sign">
                                                            </span>
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col className="4">
                                                <Button color="primary" onClick={() => saveConfig()}>Salvar</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Nome</th>
                                        <th>Ultimo envio</th>
                                        <th>Pause</th>
                                        <th>Editar</th>
                                        <th>Apagar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        posts.list.map(post => (
                                            <tr key={post._id}>
                                                <td>{post.name}</td>
                                                <td>{getLastsend(post.last)}</td>
                                                <td>
                                                <span style={{cursor: "pointer"}} onClick={()=>{
                                                        console.log("post.pause:",post.pause);
                                                        setModal({
                                                            status: true,
                                                            msgStatus: post.pause,
                                                            text: !post.pause ? "Deseja pausar o envio desta pontagem?" : "Deseja retomar o envio desta postagem?",
                                                            buttonOk: !post.pause ? "Pausar" : "Iniciar envio",
                                                            id: post._id,
                                                            onOk: startOrStopPost
                                                        })
                                                        }}> { !post.pause ? <VscDebugPause style={{fontSize: 18, color: "#d78914"}}/> : <VscDebugStart style={{fontSize: 18, color: "#2edd84"}}/>}</span>
                                                </td>
                                                <td><span onClick={()=>{
                                                    setFields(
                                                        {
                                                            ...fields,
                                                            id:     post._id,
                                                            group:  post.group,
                                                            name:   post.name,
                                                            time:   post.time,
                                                            text:   post.text,
                                                            weeks:  post.weeks,
                                                            method: "edit"
                                                        }
                                                    )
                                                    if(post.img) {
                                                        setImg({
                                                            has: true,
                                                            file: post.img,
                                                            origin: "server"
                                                        })
                                                    }
                                                    
                                                }} style={{cursor: "pointer"}}> {fields.id === post._id ? "Editando" : ""} <FiEdit style={{fontSize: 18, color: "#ce56c7"}}/></span></td>
                                                <td><span style={{cursor: "pointer"}} onClick={()=>{
                                                
                                                    setModal({
                                                        status: true,
                                                        text: "Deseja deletar esta Postagem?",
                                                        id: post._id,
                                                        buttonOk: "Deletar",
                                                        onOk: deletePost
                                                    });
                                            
                                                }}><AiFillDelete style={{fontSize: 18, color: "#ce56c7"}}/></span></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                :
                <>
                    <Col md="6">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col><h2>Criar Postagem</h2></Col>
                                    <Col><Button className="float-right" color="info" onClick={()=> setFields({...fields, method: "list"})}>Voltar</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Nome: <span style={{color: "red"}}>*</span></label>
                                            <Input
                                                value={fields.name}
                                                onChange={(e) => onChange(e.target.value, "name")}
                                                placeholder="Nome do Usuário"
                                                type="text"
                                                />
                                                <FormFeedback >
                                                    Nome
                                                </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md="8">
                                        <FormGroup>
                                            <label>Links de grupos: <span style={{color: "red"}}>*</span></label>
                                            <textarea
                                                rows={8}
                                                style={{
                                                    width: "100%",
                                                    borderRadius: 8,
                                                    backgroundColor: "#fff",
                                                    fontSize: 15,
                                                    padding: 8
                                                }}
                                                value={fields.group}
                                                onChange={(e) => onChange(e.target.value, "group")}
                                                placeholder="exemplo: 
                                                https://www.facebook.com/groups/DataScienceGroup
                                                https://www.facebook.com/groups/DataScienceGroup2
                                                https://www.facebook.com/groups/DataScienceGroup3"
                                                type="text"
                                                />
                                                <FormFeedback>
                                                    Link do group
                                                </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col className="py-4" md="12">
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            {
                                                img.has &&
                                                <img src={img.origin == "local" ? URL.createObjectURL(img.file) : server_url.WSSERVER + "/" + img.file} style={{
                                                    height: 400
                                                }}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col  md="12">
                                        <FileSelector>
                                            <section>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 25,
                                                    border: drag ? "1px dashed green"  : `1px dashed #f5f5f5`,
                                                    
                                                }} onDragOver={(e)=>{
                                                    let event = e ;
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    setDrag(true)
                                                }} onDragLeave={()=>{
                                                    setDrag(false)
                                                }}

                                                onDrop={(e)=>{
                                                    var file = e.dataTransfer.items[0].getAsFile();
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setDrag(false)
                                                    onChangeFile(file)
                                                }}>
                                                    <span
                                                        style={{
                                                            color: "#fff"
                                                        }}>{drag ? "Soltar" : "Solte uma imagem Aqui"}</span>
                                                </div>
                                            </section>
                                        </FileSelector>
                                    </Col>
                                    <Col md="12">
                                        <FacebookTextEditor fields={fields} setFields={setFields}/>
                                    </Col>
                                </Row>
                          
                           
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label for="min-date-time">
                                                Enviar mensagens às:
                                            </Label>
                                                <Input
                                                    onChange={(e)=> setFields({...fields, time: {...fields.time, min: e.target.value}})} 
                                                    id="min-date-time"
                                                    value={fields.time.min}
                                                    name="min-date-time"
                                                    placeholder="time placeholder"
                                                    type="time"
                                                    />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h5 className="mt-5">Dias da semana</h5>
                                        <FormGroup check>
                                            <Label check className="mx-1 mt-3">
                                                <span>Domingo</span>
                                                <Input
                                                    type="checkbox"
                                                    name="week0"
                                                    checked={fields.weeks.week0}
                                                    onClick={() => {
                                                        setFields({...fields, weeks: {...fields.weeks, week0: !fields.weeks.week0}});
                                                    }}
                                                    />
                                                    <span className="form-check-sign">
                                                    </span>
                                            </Label>

                                            <Label check className="mx-1 mt-3">
                                                Segunda-feira
                                                <Input
                                                    type="checkbox"
                                                    name="week1"
                                                    checked={fields.weeks.week1}
                                                    onClick={() => {
                                                        setFields({...fields, weeks: {...fields.weeks, week1: !fields.weeks.week1}});
                                                    }}
                                                    />
                                                    <span className="form-check-sign">
                                                    </span>
                                            </Label>
                                            <Label check className="mx-1 mt-3">
                                                Terça-feira
                                                <Input
                                                    type="checkbox"
                                                    name="week2"
                                                    checked={fields.weeks.week2}
                                                    onClick={() => {
                                                        setFields({...fields, weeks: { ...fields.weeks,  week2: !fields.weeks.week2}});
                                                    }}
                                                    />
                                                    <span className="form-check-sign">
                                                    </span>
                                            </Label>
                                            <Label check className="mx-1 mt-3">
                                                Quarta-feira
                                                <Input
                                                    type="checkbox"
                                                    name="week3"
                                                    checked={fields.weeks.week3}
                                                    onClick={() => {
                                                        setFields({...fields, weeks: { ...fields.weeks, week3: !fields.weeks.week3}});
                                                    }}
                                                    />
                                                    <span className="form-check-sign">
                                                    </span>
                                            </Label>
                                            <Label check className="mx-1 mt-3">
                                                Quinta-feira
                                                <Input
                                                    type="checkbox"
                                                    name="week4"
                                                    checked={fields.weeks.week4}
                                                    onClick={() => {
                                                        setFields({...fields, weeks: {...fields.weeks, week4: !fields.weeks.week4}});
                                                    }}
                                                    />
                                                    <span className="form-check-sign">
                                                    </span>
                                            </Label>
                                            <Label check className="mx-1 mt-3">
                                                Sexta-feira
                                                <Input
                                                    type="checkbox"
                                                    name="week5"
                                                    checked={fields.weeks.week5}
                                                    onClick={() => {
                                                        setFields({...fields, weeks:{...fields.weeks, week5: !fields.weeks.week5}});
                                                    }}
                                                    />
                                                    <span className="form-check-sign">
                                                    </span>
                                            </Label>
                                            <Label check className="mx-1 mt-3">
                                                Sábado
                                                <Input
                                                    type="checkbox"
                                                    name="week6"
                                                    checked={fields.weeks.week6}
                                                    onClick={() => {
                                                        setFields({...fields, weeks:{...fields.weeks, week6: !fields.weeks.week6 }});
                                                    }}
                                                    />
                                                    <span className="form-check-sign">
                                                    </span>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span><Button onClick={()=>{
                                            if(fields.method==="create"){
                                                setModal({
                                                    status: true,
                                                    text: "Deseja Criar este Postagem?",
                                                    buttonOk: "Criar",
                                                    onOk: createPost
                                                });
                                            } else {
                                                setModal({
                                                    status: true,
                                                    text: "Deseja atualizar esta postagem?",
                                                    buttonOk: "Atualizar",
                                                    onOk: editPost
                                                });
                                            }
                                        }} style={{marginTop: 32}} color="primary">{fields.method === "create" ? "Criar Postagem" : "Atualizar"}</Button></span>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="h-auto" md="6">
                        <textarea className="p-4" value={textFormat} rows={12} style={{
                            width: "100%",
                            borderRadius: 8,
                            backgroundColor: "#fff",
                            fontSize: 20
                        }} disabled>
                        </textarea>
                    </Col>
                </>
                }
            </Row>
        </div>
    )
}


export default FacebookPost;