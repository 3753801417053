import styled from "styled-components";

export const ColumnCards = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
`;
export const ColumnCard = styled.span`
    margin: 8px;
    padding: 8px 24px;
    border-radius: 5px;
    color: #fff;
    background-color: ${p => p.selected ? "#42b348" : "#8d85e1"};
`;