import { Button, Card, CardBody, CardHeader, Col, Input, Row, Table } from "reactstrap";
import { IconLoad, ProgressBar, ProgressBox, ProgressLine, ProgressWrapper, SendingCount, SendingPercentageText, SendingText, SendingTitle, SendingTop, TableContacts } from "./style";
import {ImCheckmark} from "react-icons/im"
import {AiOutlineLoading3Quarters} from "react-icons/ai"
import {BiCommentError} from "react-icons/bi"
import { memo, useContext, useEffect, useState } from "react";
import Api from "services/api";
import * as XLSX from "xlsx";
import { AuthContext } from "contexts/AuthContext";
import MsgTimeLine from "components/MsgTimeLime";

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

const SendMsgs = ({fields}) => {
    const [auth] = useContext(AuthContext);
    const [sending, setSending] = useState({
        status: false,
        type: "",
        name: "",
        sended: false,
        percentage: 0
    });
    const [sended, setSended] = useState([])
    
    function randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }

    const geType = (type) => {
        if(type == "video") {
            return "o Vídeo"
        }
        if(type == "image") {
            return "a Imagem"
        }
        return "o Arquivo"
    }

    const send = async () => {
        let msgs = fields.mensagens;
        let _msgs = [];
        for (let x = 0; x < msgs.length; x++) {
            let hasMedia = false;
            let i = msgs[x];
            let obj = {...i}
            if(i.media.has && i.media.origin == "local") {
                setSending({status: true, name: i.media.file.name, type: geType(i.type), progress: 0})
                hasMedia = true;
                var file = new FormData();
                const fileSize = i.media.file.size;
                file.append("media",i.media.file)
                try {
                    const res = await Api("post", "send/upload", file, {
                        headers: {
                            "x-access-token": localStorage.getItem("terces")
                        },
                        onUploadProgress: progressEvent => {
                            setSending({status: true, sended: false, name: i.media.file.name, type: geType(i.type), percentage: percentage(progressEvent.loaded, progressEvent.loaded >= fileSize ? progressEvent.loaded : fileSize)})
                        }
                    });
                    console.log("res:",res);
                    if(res.data && res.data.file && res.data.file.size) {
                        obj.media.filename = res.data.file.filename
                    }
                    console.log("obj:",  obj);

                } catch (error) {
                    console.log("error:",error);
                }
            }
            _msgs.push({
                media: {
                    filename:  obj.media.has ? obj.media.filename : "",
                    has: obj.media.has,
                    type: obj.media.type,
                },
                texto: obj.texto
            })
        }
        
        let mensagens = _msgs.map( i => {
            return {
                media: {
                    filename:  i.media.has ? i.media.filename : "",
                    has: i.media.has,
                    type: i.media.type,
                },
                texto: i.texto
            }
        })
        
        const data = {
            name: fields.msgTitle,
            mensagens,
            date: (new Date()).toLocaleString(),
            columns: fields.columns,
            colunas: fields.cols,
            list: fields.rows
        }
        console.log("fields:",fields);
        console.log("data:",data);
        try {
            const fileSize = JSON.stringify(data).length;
            const res = await Api("post", "send/create", data, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                },
                onUploadProgress: progressEvent => {
                    setSending({status: true, sended: false, name: "", type: "Msg", percentage: percentage(progressEvent.loaded, progressEvent.loaded >= fileSize ? progressEvent.loaded : fileSize)})
                }
            });
            setSending({status: true, sended: false, name: "", type: "Sending", percentage: 100})

        } catch (error) {
            console.log("error:",error);
        }
       
    }
    

    return (
        <Row>
            <Col md="6">
                <Card>
                    <CardBody>
                        <ProgressWrapper>
                            <ProgressBox>
                                <SendingTop>
                                {
                                    sending.status ? 
                                    <SendingText>{ sending.type != "Sending" ? "Salvando" : "Enviando Mensagens"} {sending.type != "Sending" ? sending.type != "Msg" ? sending.type : "Mensagens" : ""} { sending.type != "Msg" ? sending.name : ""} no servidor</SendingText>
                                    : <SendingText/>
                                }
                                    </SendingTop>
                                    <div style={{padding: "0 32px"}}>
                                        <MsgTimeLine fields={fields} currentMsg={false} vh={50}/>
                                    </div>
                                    <SendingPercentageText>{sending.percentage ? sending.percentage : '0'}%</SendingPercentageText>
                                    <ProgressBar>
                                        <ProgressLine percentage={sending.percentage ? sending.percentage : '0'}/>
                                    </ProgressBar>
                                {
                                    !sending.status ?
                                    <span><Button onClick={()=>send()} className="btn-fill" color="primary">Enviar Mensagens</Button></span> : null
                                }
                         
                            {/* {
                                sendErrors.length
                                ?
                                    <span>
                                        <Button style={{marginTop: 24}} onClick={()=>{
                                        const workbook = XLSX.utils.book_new();
                                        var ws_data = [
                                            cols,
                                            ...sendErrors
                                        ];
                                        var ws = XLSX.utils.aoa_to_sheet(ws_data);
                                        XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
                                        XLSX.writeFile(workbook, "Relatorio.xlsx");
                                        }} className="btn-fill" color="primary">Gerar Arquivo com Contatos Sem Whatsapp</Button>
                                    </span>
                                :  null
                            } */}
                            </ProgressBox>
                        </ProgressWrapper>
                    </CardBody>
                </Card>
            </Col>
        </Row>

    )
}

export default memo(SendMsgs)
