
import React, { useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader
} from "reactstrap";
import { AuthContext } from "contexts/AuthContext";
import Api from "services/api";

function AdminNavbar(props) {
    const [auth] = useContext(AuthContext);
    const [collapseOpen, setcollapseOpen] = React.useState(false);
    const [modalSearch, setmodalSearch] = React.useState(false);
    const [color, setcolor] = React.useState("navbar-transparent");
    

    const desconnectWhatsap = async () => {
        try {
            const res = await Api("get", "whatsapp/logout", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            console.log("res:",res);
        } catch (error) {
            console.log("error:",error);
        }
    } 
  
    React.useEffect(() => {
        window.addEventListener("resize", updateColor);
        // Specify how to clean up after this effect:
        return function cleanup() {
        window.removeEventListener("resize", updateColor);
        };
    });
    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && collapseOpen) {
        setcolor("bg-white");
        } else {
        setcolor("navbar-transparent");
        }
    };
    // this function opens and closes the collapse on small devices
    const toggleCollapse = () => {
        if (collapseOpen) {
        setcolor("navbar-transparent");
        } else {
        setcolor("bg-white");
        }
        setcollapseOpen(!collapseOpen);
    };
    // this function is to open the Search modal
    const toggleModalSearch = () => {
        setmodalSearch(!modalSearch);
    };
    return (
        <>
        <Navbar className={classNames("navbar-absolute", color)} expand="lg">
            <Container fluid>
            <div className="navbar-wrapper">
                <div
                className={classNames("navbar-toggle d-inline", {
                    toggled: props.sidebarOpened
                })}
                >
                <NavbarToggler onClick={props.toggleSidebar}>
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                </NavbarToggler>
                </div>
                <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                {props.brandText}
                </NavbarBrand>
            </div>

            <NavbarToggler onClick={toggleCollapse}>
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
            </NavbarToggler>
            <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar>
                <div style={{display: "flex", alignItems: "center"}}>
                    <p style={{fontSize: 16}}>Olá, {auth.auth.name}</p>
                </div>
                <UncontrolledDropdown nav>
                    <DropdownToggle
                    caret
                    color="default"
                    nav
                    onClick={(e) => e.preventDefault()}
                    >
                    <div className="photo">
                        <img alt="..." src={require("assets/img/anime3.png")} />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none">Log out</p>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                    
                    <NavLink tag="li">
                        <DropdownItem onClick={()=>{
                        window.location = "/admin/users"
                        }} className="nav-item">Usuários</DropdownItem>
                    </NavLink>
                    <DropdownItem divider tag="li" />

                    <NavLink tag="li">
                        <DropdownItem onClick={() => desconnectWhatsap()} className="nav-item">Desconectar Whatsapp</DropdownItem>
                    </NavLink>
                    <DropdownItem divider tag="li" />

                    <NavLink tag="li">
                        <DropdownItem onClick={()=>{
                        localStorage.removeItem("terces");
                        window.location = "/login"
                        }} className="nav-item">Sair</DropdownItem>
                    </NavLink>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
                </Nav>
            </Collapse>
            </Container>
        </Navbar>
        </>
    );
}

export default AdminNavbar;
