import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { useContext, useEffect, useState } from "react";
import { RiCheckboxFill, RiCheckboxBlankLine } from "react-icons/ri";
import { FormGroup, Input, Label, Table } from "reactstrap";

export default function TableColumnSelect({fields, setColumns}){


    const changeNumber = (value, index) => {
        let has = false;
        let colunas = ([...fields.columns]).map(i=>{
            if(index==i.index){
                has = true;
                return {
                    ...i,
                    wpp: value
                }
            }
            return {
                ...i,
                wpp: false
            }
        });
        setColumns(colunas, value)
    }

    const setVar = (value, index) => {
        let has = false;
        let colunas = ([...fields.columns]).map(i=>{
            if(i.wpp) {
                has = true; 
            }
            if(index==i.index){
                return {
                    ...i,
                    var: value.replace(/[^a-zA-Z]/g,'')
                }
            } 
            return i
        });
        setColumns(colunas, has)
    }

    const setSelect = (value, index) => {
        let colunas = ([...fields.columns]).map(i=>{
            if(index==i.index){
                return {
                    ...i,
                    use: value
                }
            }
            return i
        });
        setColumns(colunas)
    }


    return (
        <Table style={{overflow: "none"}} className="tablesorter">
            <thead className="text-primary">
                <tr>
                    <th>Usar Coluna</th>
                    <th>Nome</th>
                    <th>Variável</th>
                    <th>Numero de Whatsapp</th>
                </tr>
            </thead>
            <tbody>

                {
                   fields.columns.map((i)=>(
                        <tr key={`select-col-${i.index}`}>
                            <td>
                  
                                <FormGroup check {...(i.disabled ? {disabled: true}: {})}>
                                    <Label check {...(i.disabled ? {disabled: true}: {})}>
                                        <input
                                            {...(i.disabled ? {disabled: true}: {})}
                                            defaultValue=""
                                            type="checkbox"
                                            onChange={(e) => setSelect(e.target.checked, i.index)}
                                            />
                                        <span className="form-check-sign">
                                        </span>
                                    </Label>
                                </FormGroup>
                            </td>
                            <td>{i.name}</td>

                            <td>
                                {
                                    i.use &&
                                    <Input {...(i.disabled ? {disabled: true}: {})} type="text" value={i.var} onChange={(e) => setVar(e.target.value, i.index)}/>
                                }
                            </td>
                            
                            <td>    
                                <FormGroup check {...(i.disabled ? {disabled: true}: {})}>
                                    <Label check {...(i.disabled ? {disabled: true}: {})}>
                                        <input {...(i.disabled ? {disabled: true}: {})}
                                            type="checkbox"
                                            onChange={(e) => changeNumber(e.target.checked, i.index)}
                                            />
                                        {
                                            i.wpp ? <RiCheckboxFill style={{fontSize: 24, color: "#e14eca"}}/> : <RiCheckboxBlankLine  style={{fontSize: 24, color: "#e14eca"}}/>
                                        }
                                      
                                    </Label>
                                </FormGroup>
                            </td>
                        </tr>  
                    ))
                }
            </tbody>
        </Table>            
    );
}