import { useContext, useEffect, useState } from "react";
import {ThemeContext} from "contexts/ThemeContext";
import Api from "services/api";
import QRCodePublic from "components/QRCodePublic";
import Loading from "components/Loading";




export default function WhatsappPublic(props) {
    const {theme, changeTheme} = useContext(ThemeContext);
    const [whatsapp, setWhatsapp] = useState({queried: false, data: {}});

    if(theme==="") changeTheme("white-content")

    const getWhatsapp = async () => {
        try {
            const res = await Api("get", "whatsapp-public/status?id="+props.match.params.id); 
           setWhatsapp({queried: true, data: res.data.whatsapp})
        } catch (error) {
            console.log("error:",error);
        }
    }

    useEffect(()=>{
        setTimeout( async () => {            
            try {
                await getWhatsapp();
            } catch (error) {
                console.log("error:",error);
            }

        }, 2000);
    },[whatsapp]);

    if(!whatsapp.queried) return <Loading/>

    return <QRCodePublic src={whatsapp.data.qr} status={whatsapp.data.status}/>
}