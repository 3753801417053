import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, CustomInput, FormGroup, Input, Label, Row, Table,Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PickerEmoji from 'emoji-picker-react';
import Api from "services/api";
import styled from "styled-components";
import TableColumnSelect from "../components/TableColumnSelect";
import WhatappTextEditor from "../components/WhatsappTextEditor";
import Loading from "components/Loading";
import QRCode from "components/QRCode";
import { useContext } from "react";
import {ThemeContext} from "contexts/ThemeContext";
import { AuthContext } from "contexts/AuthContext";
import SendMsgs from "components/SendMsgs";
import * as XLSX from "xlsx";
import ReactNotificationAlert from "react-notification-alert";
import PhoneNotConnected from "components/PhoneNotConnected";
import MsgTimeLine from "components/MsgTimeLime";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import vars from "../services/constants.json"
const url = vars.WSSERVER;

axios.defaults.baseURL = url;
let _timer = 0;
const newMsg = () => {
    return {
        id: uuidv4(),
        method: "new",
        media: {
            has: false,
            type: "",
            filename: "",
            origin: "local"
        },
        texto: "",
    }
}

function Chat(){
    const notificationAlertRef = useRef(null);
    const {theme, changeTheme} = useContext(ThemeContext);
    const [fields, setFields] = useContext(AuthContext);
    const [drag, setDrag] = useState(false);
    const [currentMsg, setCurrentMsg] = useState(newMsg);
    const [msgModels, setMsgModels] = useState(false);

 
    const [sending, setSending] = useState({
        status: false,
        type: "",
        name: "",
        sended: false,
        percentage: 0
    });

    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });
        
    const [whatsapp, setWhatsapp] = useState({
        status: "loading",
        listing: false
    });
        
    function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }

    const geType = (type) => {
        if(type == "video") {
            return "o Vídeo"
        }
        if(type == "image") {
            return "a Imagem"
        }
        return "o Arquivo"
    }

    const parseCols = (cols) => {
        console.log("cols:",cols);
        return cols.map((i,k)=>{
            return {
                index: k,
                name: i,
                disabled: false,
                wpp: false,
                use: false,
                var: ""
            }
        })
    }

    const dateCols = (columns) => {
        return [
            {
                index: columns.length,
                name: "Hoje",
                wpp: false,
                disabled: true,
                use: true,
                var: "hoje"
            },
            {
                index: columns.length + 1,
                name: "Amanha",
                wpp: false,
                disabled: true,
                use: true,
                var: "amanha"
            }
        ]
    }

    const dateRows = () => {
    
    }

    const createSLXArray = (xls, file) => {
        let columns = [];
        let rows    = [];
        if(xls[0]) {
            const obj = xls[0];
            Object.keys(obj).forEach(key => {
                columns.push(key);
            });
            for (let i = 0; i < xls.length; i++) {
                const row = xls[i];
                let _row = [];
                Object.keys(row ).forEach(key => {
                    _row.push(row[key])
                });
                const date = new Date();
                let day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
                let month = (date.getMonth() + 1) <= 9 ? "" + (date.getMonth() + 1) : (date.getMonth() + 1);
                let year = date.getFullYear();
                _row.push([`${day}/${month}/${year}`]);
                date.setDate(date.getDate() + 1);
                day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
                month = (date.getMonth() + 1) <= 9 ? "" + (date.getMonth() + 1) : (date.getMonth() + 1);
                year = date.getFullYear();
                _row.push([`${day}/${month}/${year}`]);
                rows.push(_row);
            }
        }
        setFields({...fields, contactfile: file, cols: columns, columns: [...parseCols(columns), ...dateCols(columns)], rows, contacts: [[...columns], ...rows]});
    }

    const xlsReader = (file) => {
        new Promise((resolve, reject)=>{
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = function(e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, {type: 'binary'});
                var wb = XLSX.utils.sheet_to_json(workbook.Sheets[Object.keys(workbook.Sheets)[0]], {raw: true, defval:null})
                createSLXArray(wb, file)
            };
        })
    }

    const onChangeFile = (file) => {
        setFields({...fields, contactfile: file})
        xlsReader(file);
    }

    const setColumns = (cols, wpp) =>{
        setFields({...fields, columns: cols, wpp})
    }
    
    const makeMsgModels = (model) => {
        const models = []; 
        for (let i = 0; i < model.length; i++) {
            const msgs = model[i].mensagens;
            let _msgs = [];
            for (let x = 0; x < msgs.length; x++) {
                const media = {
                    has: msgs[x].media.has ? true : false,
                    filename: msgs[x].media.has ? msgs[x].media.filename : "",
                    type: msgs[x].media.has ? msgs[x].media.type : "",
                    origin: "server"
                }
                _msgs.push({
                    id: uuidv4(),
                    media,
                    texto: msgs[x].texto
                })
            }
            models.push({
                id: model[i]._id,
                name: model[i].name,
                mensagens: _msgs
            })
        }
        console.log("models:",models);
        setMsgModels(models)
    }
    
    const createMsg = async () => {
        let msgs = fields.mensagens;
        let _msgs = [];
        for (let x = 0; x < msgs.length; x++) {
            let hasMedia = false;
            let i   = msgs[x];
            let obj = {...i};
            if(i.media.has) {
                setSending({status: true, name: i.media.file.name, type: geType(i.type), progress: 0})
                hasMedia = true;
                var file = new FormData();
                const fileSize = i.media.file.size;
                file.append("media",i.media.file)
                try {
                    const res = await Api("post", "send/upload", file, {
                        headers: {
                            "x-access-token": localStorage.getItem("terces")
                        },
                        onUploadProgress: progressEvent => {
                            setSending({status: true, sended: false, name: i.media.file.name, type: geType(i.type), percentage: percentage(progressEvent.loaded, progressEvent.loaded >= fileSize ? progressEvent.loaded : fileSize)})
                        }
                    });
                    if(res.data && res.data.file && res.data.file.size) {
                        obj.media.filename = res.data.file.filename
                    }
                } catch (error) {
                    console.log("error:",error);
                }
            }
            _msgs.push({
                media: {
                    filename: obj.media.has ? obj.media.filename : "",
                    has: obj.media.has,
                    type: obj.media.type
                },
                texto: obj.texto
            })
        }

        let mensagens = _msgs.map( i => {
            return {
                media: {
                    filename:  i.media.has ? i.media.filename : "",
                    has: i.media.has,
                    type: i.media.type
                },
                texto: i.texto
            }
        })

        const data = {
            name: fields.msgTitle,
            mensagens,
            date: (new Date()).toLocaleString()
        }

        try {
            const res = await Api("post", "message/create", data, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data){
                notify("Modelo Salvo com Sucesso!", "success");
                getMsgModels()
            }
            setFields({
                ...fields,
                mensagens: [],
                msgTitle: ""
            })
            setCurrentMsg(newMsg())
        } catch (error) {
            console.log("error:",error);
        }
    }
    
    const getMsgModels = async () => {
        try {
            const res = await Api("get", "message/list", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            makeMsgModels(res.data)
        } catch (error) {
            console.log("error:",error);
        }
    }

    const toggle = () => setModal({...modal, status: !modal.status});

    const setMsg = async () => {
        setFields({...fields, ready: true})
    }

    const addMsg = async () => {

        if(!currentMsg.media.has && currentMsg.texto == "") return

        if(currentMsg.method == "edit") {
            const msgs = fields.mensagens;
            let _msgs = [];
            for (let i = 0; i < msgs.length; i++) {
                if(msgs[i].id === currentMsg.id) {
                    _msgs.push(currentMsg)
                } else {
                    _msgs.push(msgs[i])
                }
            }
            setFields({...fields, mensagens: _msgs});
            setCurrentMsg(newMsg());
            return 
        }
        
        setFields({...fields, mensagens: [ {
            ...currentMsg
        }, ...fields.mensagens]});
        setCurrentMsg(newMsg());
    }

    const notify = (text, type) => {
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    
    const setUniqueWpp  = (value) => {
        setFields({...fields, uniqueWpp: value.replace(/[^\d]/g, "")})
    }

    const resetWhatsapp = async () => {
        try {
            const res = await Api("get", "whatsapp/new", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data){
                setWhatsapp(res.data.whatsapp);
            }
        } catch (error) {

        }
    }

    const sendUniqueNumber = () => {
        const columns = ['celular'];
        const rows    = [[`${fields.uniqueWpp}`]];
        const parsedCol = {
            index: 0,
            name: 'celular',
            disabled: true,
            wpp: true,
            use: true,
            var: ""
        }
        setFields({...fields, hasContacts: true, contactsReady: true, contactfile: {name: ""}, cols: columns, columns: [parsedCol, ...dateCols(columns)], rows, contacts: [[...columns], ...rows]});
    }

    const getWhatsapp = async () => {
        const controller = new AbortController();
        const axis_timer = setTimeout( async () => {
            console.log("can reset!!!");
            controller.abort();
            const res = await axios.get('/whatsapp/restart', {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            })
            setWhatsapp(res.data.whatsapp);
        }, 20000);
        try {
            const res = await axios.get('/whatsapp/status', {
                signal: controller.signal,
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            })
            clearTimeout(axis_timer);
            if(res.data){
               
                setWhatsapp(res.data.whatsapp);
            }
        } catch (error) {
            console.log("error:",error);
        }

    }

    useEffect(()=>{
        if(fields.whatsapp.status === "loading"){
            getWhatsapp();
        } else if(fields.whatsapp.status != "qrReadFail"){
            const controller = new AbortController();
            const axis_timer = setTimeout( async () => {
                console.log("can reset!!!");
                controller.abort();
                const res = await axios.get('/whatsapp/restart', {
                    headers: {
                        "x-access-token": localStorage.getItem("terces")
                    }
                })
                setWhatsapp(res.data.whatsapp);
            }, 20000);
            setTimeout( async () => {
                //browserClose possível retorno
             
                try {
                    const res = await axios.get('/whatsapp/status', {
                        signal: controller.signal,
                        headers: {
                            "x-access-token": localStorage.getItem("terces")
                        }
                    })
                    clearTimeout(axis_timer);
                    if(res.data){
                     
                        setWhatsapp(res.data.whatsapp);
                    }
                } catch (error) {
                    console.log("error:",error);
                }
   
            }, 2000);
        }
        if(JSON.stringify(whatsapp) != JSON.stringify(fields.whatsapp)) {
            setFields({...fields, whatsapp: whatsapp})
        }
    },[whatsapp]);


    useEffect(()=>{
        localStorage.setItem("lastTheme",theme);
    },[theme])
    
    const loadingTexts = {
        "loading": "Verificando Whatsapp",
        "logingStarted": "Verificando Whatsapp"
    }

    const lastTheme = localStorage.getItem("lastTheme");
    
    if(!fields.whatsapp.status || fields.whatsapp.status === "loading" || fields.whatsapp.status === "logingStarted"){
        return (
            <div className="content">
                <Button onClick={ () => {
                    resetWhatsapp()
                    }} className="btn-fill" color="primary" type="submit">
                    Gerar Nova Sessão
                </Button>
                <Loading text={loadingTexts[whatsapp.status]}/>
            </div>
        )
    }
    

    if(fields.whatsapp.status==="phoneNotConnected") {
        return <div className="content"><PhoneNotConnected/></div>
    }
    
    if((fields.whatsapp.status === "notLogged" && fields.whatsapp.qr) || whatsapp.status === "qrReadFail"){
        if(theme==="") changeTheme("white-content")
        return <div className="content"><QRCode getWhatsapp={getWhatsapp} src={fields.whatsapp.qr} status={fields.whatsapp.status}/></div>
    } else {
        if(lastTheme == "white-content") changeTheme("")
    }

    if(fields.ready) {
        return (
            <div className="content">
                <Button onClick={()=>setFields({...fields, ready: false})} className="btn-fill" color="secondary" type="submit">
                    Voltar Edição de Texto
                </Button>            
                <SendMsgs fields={fields} />
            </div>
        )
    }

    if(!fields.hasContacts){
        return (
            <div className="content">
                <Row>
                    <Col md="6">
                        <Card>
                            <CardHeader>
                                <h3>Planilha</h3>
                            </CardHeader>
                            <CardBody style={{overflow: "auto", maxHeight: 600}}>
                                <Table>
                                    <thead className="text-primary">
                                        <tr>
                                            {
                                                fields.cols.map((i, k)=>(
                                                    <th key={`xls-col-${k}`}>{i}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fields.rows.map((i, k)=>(
                                                <tr>
                                                    {
                                                        i.map(x => (
                                                            <td>{x}</td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardHeader>
                                <h3>Mensagem única</h3>
                                <Row>
                                    <Col>
                                        <Input type="text" maxlength="11" placeholder="Digite um múmero de whatsapp" onChange={(e) => setUniqueWpp(e.target.value)}/>
                                    </Col>
                                    <Col>
                                        <Button onClick={() => sendUniqueNumber()} className="btn-fill m-0" color="primary" type="submit" disabled={!(fields.uniqueWpp.length >= 10)}>
                                            Enviar Mensagem
                                        </Button>
                                    </Col>
                                </Row>
                                <h3 className="mt-5">Enviar Arquivos</h3>

                            </CardHeader>
                            <CardBody className="pt-5">
                                
                                <FormGroup>
                                    <InputFile>
                                        <label>
                                            <input
                                                style={{display: 'none'}}
                                                type="file"
                                                name="contactfile"
                                                onChange={(e)=> onChangeFile(e.target.files[0])} />
                                            <InputBtn selected={!!fields.contactfile} className="input-btn"> {!!fields.contactfile ? <> <i className="tim-icons icon-check-2"></i> Arquivo selecionado </> : "Escolher Arquivo" }</InputBtn>
                                            <span className="input-txt">{ fields.contactfile ? fields.contactfile.name : 'Nenhum arquivo escolhido' }</span>
                                        </label>
                                    </InputFile>
                                </FormGroup>
                                    <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: 25,
                                            border: drag ? "1px dashed green"  : `1px dashed ${theme == "white-content" ? "#444" : "#f5f5f5"}`,
                                            
                                        }} onDragOver={(e)=>{
                                            let event = e ;
                                            event.stopPropagation();
                                            event.preventDefault();
                                            setDrag(true)
                                        }} onDragLeave={()=>{
                                            setDrag(false)
                                        }}

                                        onDrop={(e)=>{
                                            var file = e.dataTransfer.items[0].getAsFile();
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setDrag(false)
                                            onChangeFile(file)
                                        }}>
                                        <span
                                        style={{
                                            color: theme == "white-content" ? "#444" : "#fff"
                                        }}>{drag ? "Soltar" : "Solte um Arquivo Aqui"}</span>
                                    </div>
                            </CardBody>
                            
                            <CardFooter>
                                {
                                    !fields.hasContacts &&
                                    <Button onClick={()=>setFields({...fields, hasContacts: true})} className="btn-fill" color="primary" type="submit" disabled={!!!fields.columns.length}>
                                        Enviar
                                    </Button>
                                }
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

    if(fields.hasContacts && !fields.contactsReady){
        return (
            <div className="content">
                <ReactNotificationAlert ref={notificationAlertRef} />
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h3 className="title">Criar Variáveis</h3>
                            </CardHeader>
                            <CardBody>
                                <Button onClick={()=>setFields({...fields, hasContacts: false})} className="btn-fill" color="secondary" type="submit">
                                    Voltar para Envio de Arquivo
                                </Button>
                                <TableColumnSelect setColumns={setColumns} fields={fields}/>
                            </CardBody>
                            <CardFooter>
                                <Button disabled={!!!fields.wpp} onClick={()=>setFields({...fields, contactsReady: true})} className="btn-fill" color="primary" type="submit">
                                    Próximo
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

    if(fields.contactsReady){
        return (
            <div className="content">
                <ReactNotificationAlert ref={notificationAlertRef} />
                <Modal isOpen={modal.status} toggle={toggle}>
                    <ModalHeader toggle={toggle}></ModalHeader>
                    <ModalBody>
                        {modal.text}
                    </ModalBody>
                    <ModalFooter style={{padding: "24px"}}>
                        <Button color="primary" onClick={()=>{
                            modal.onOk();
                            setModal({
                                status: false,
                                text: "",
                                buttonOk: "",
                                onOk: false
                            })
                        }}>{modal.buttonOk}</Button>{' '}
                        <Button color="secondary" onClick={toggle}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
                <Row>
                    <Col md="8">
                        <Card>
                            <CardHeader>
                                <h3 className="title">Editor Texto</h3>
                            </CardHeader>
                            <CardBody>
                                <Button onClick={()=>setFields({...fields, contactsReady: false})} className="btn-fill" color="secondary" type="submit">
                                    Voltar para Configurações do Arquivo
                                </Button>
                                <Row>
                                    <Col>
                                        <WhatappTextEditor getMsgModels={getMsgModels} msgModels={msgModels} setMsgModels={setMsgModels} currentMsg={currentMsg} setCurrentMsg={setCurrentMsg} newMsg={newMsg} fields={fields} setFields={setFields}/>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <FormGroup>
                                    <Label>Título da mensagem:</Label>
                                    <Input value={fields.msgTitle} type="text" onChange={(e) => {
                                        setFields({...fields, msgTitle: e.target.value})
                                    }}/>
                                </FormGroup>
                                <Row>
                                    <Col>
                                        <Button onClick={()=>addMsg()} className="btn-fill" color="secondary" type="submit" disabled={!( currentMsg.media.has || currentMsg.texto != "" ? true : false)}>
                                            { currentMsg.method === "new" ? "Adicionar Mensagem" : "Atualizar"}
                                        </Button>
                                        <Button onClick={()=>setMsg()} className="btn-fill" color="primary" type="submit" disabled={( fields.msgTitle == "" || fields.mensagens.length === 0 ? true : false)}>
                                            Próximo
                                        </Button>
                                    </Col>
                                    <Col>
                                        <div className="d-flex justify-content-end">
                                            <Button onClick={ () => {
                                                setModal({
                                                    status: true,
                                                    text: "Deseja Salvar Este Modelo de Mensagem?",
                                                    buttonOk: "Salvar",
                                                    onOk: createMsg
                                                })
                                            }} className="btn-fill" color="success" type="submit" disabled={( fields.msgTitle == "" || fields.mensagens.length === 0 ? true : false)}>
                                                Salvar Modelo
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardHeader>
                                <h3 className="title">Editor Texto</h3>
                            </CardHeader>
                            <CardBody>
                                <MsgTimeLine fields={fields} setFields={setFields} currentMsg={currentMsg} setCurrentMsg={setCurrentMsg} vh={70}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
    
const InputFile = styled.div`
    padding: 4px 16px;
    label {
        width: 100%;
        height: auto;
    }
    .input-btn {

    }
    .input-txt {
        border: 1px solid rgba(255, 255, 255, .1);
        padding: 4px 12px;
    }
`;

const InputBtn = styled.span`
    color: ${p => p.selected ? "#fff" : "#444"};;
    padding: 4px 12px;
    background-color: ${p => p.selected ? "#0e7c1c" : "#fff"};
`;

export default memo(Chat);