import styled from "styled-components";

export const AuthWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const AuthBox = styled.div`
    width: 400px;
`;