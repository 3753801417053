import { AuthContext } from "contexts/AuthContext";
import { useContext, useRef, useState } from "react";
import ReactNotificationAlert from "react-notification-alert";
import { Redirect } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import Api from "services/api";
import { AuthBox, AuthWrapper } from "./style";
import { get_relatory_url } from "utils/get_relatory_url";
export default function Auth({path}){
    
    const [isAuth, setIsAuth] = useState(false);

    const notificationAlertRef = useRef(null);

    const [authData, setAuthData] = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    
    const [fields, setFields] = useState({
        method: path,
        username: "",
        password:  ""
    });

    const [fieldsStatus, setFieldsStatus] = useState({
        username: "",
        password:  ""
    });

    const validFields = () => {
        let err = {
            username: "",
            password:  ""
        }
        let valid = true;
        if(fields.username.length == 0) {
            err.username = "Campo obrigatório!"
            valid = false;
        }
        if(fields.password.length == 0) {
            err.password = "Campo obrigatório!"
            valid = false;
        }
        if(!valid){
            setFieldsStatus(err)
        }
        return valid
    }

    const onChange = (value, name) => {
        if(name==="username") {
            value = value.replace(/[^0-9a-zA-Z.]/g, '');
        }
        if(name==="password") {
            value = value.replaceAll(" ", "");
        }
        setFields({...fields, [name]: value})
    }

    const submit = async () => {
        setLoading(true)
        if(validFields()){
            const data = {
                username: fields.username,
                password: fields.password
            }
            try {
                const res = await Api("post", "auth/signin", data);
                if(res.data.accessToken) {
                    let _admin = false;
                    res.data.roles.map(i=>{
                        if(i=="ROLE_ADMIN"){
                            _admin = true;
                        }
                    })
                    localStorage.setItem("terces", res.data.accessToken);
                    setAuthData(res.data);

                    if(window.location.hostname.includes(get_relatory_url())) {
                        if(_admin) {
                            window.location = "/relatorios"
                        } else {
                            notify("tr", "O Usuário deve ser um administrador!", "danger")
                        }
                    } else {
                        window.location = "/admin/message";
                    }

                } else {
                    notify("tr", res.data.message, "danger")
                }
            } catch (error) {
                console.log("error:",error);
                notify("tr", error.response.data.message, "danger")
            }
        }
        setLoading(false)
    }

    const notify = (place, msg, type) => {
        var options = {};
        options = {
          place: place,
          message: (
            <div>
              <div>
                {msg}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    if(isAuth) {
        let _admin = false;
        authData.data.roles.map(i=>{
            if(i=="ROLE_ADMIN"){
                _admin = true;
            }
        })
        if(window.location.hostname.includes(get_relatory_url())) {
            if(_admin) {
                return  <Redirect to={"/relatorios"}/>
            } else {
                notify("tr", "O Usuário deve ser um administrador!", "danger")
            }
        } else {
            return  <Redirect to={"/admin/message"}/>
        }
        return  <Redirect to={"/admin"}/>
    }
    return (
        <AuthWrapper>
            <ReactNotificationAlert ref={notificationAlertRef} />
            
            <AuthBox>
                <Card>
                    <CardHeader>
                        <h3>Login</h3>
                    </CardHeader>
                    <CardBody>
                        <FormGroup style={{marginTop: 4}}>
                            <Label>Nome de usuário:</Label>
                            <Input value={fields.username} onChange={(e) => onChange(e.target.value, 'username')} type="text" invalid={fieldsStatus.username != ""}/>
                            <FormFeedback >
                                {fieldsStatus.username}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup style={{marginTop: 24}}>
                            <Label>Senha:</Label>
                            <Input value={fields.password} onChange={(e) => onChange(e.target.value, 'password')} type="password" invalid={fieldsStatus.password != ""}/>
                            <FormFeedback >
                                {fieldsStatus.password}
                            </FormFeedback>
                        </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Button onClick={()=>submit()} color="primary" disabled={loading}>{ loading ? "Logando..." : "Login" }</Button>
                    </CardFooter>
                </Card>
            </AuthBox>
        </AuthWrapper>
    )
}