import styled from "styled-components";


export const ModalTable = styled.div`
    max-height: 500px;
    overflow: auto;
`;

export const Badge = styled.span`
    background-color: ${p => p.color};
    padding: 8px 12px;
    border-radius: 0.3rem;
    text-shadow: 1px 1px rgba(0,0,0, .3);
`;

export const InfoTitle = styled.h4`
    font-size: 15px;
    font-weight: 600;
    span {
        font-weight: 300;
    }
`;