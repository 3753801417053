import { createContext, useState } from "react";

export const AuthContext = createContext();

export default function AuthProvider({children}){
    const [authData, setAuthData] = useState({
        auth: {},
        id: "",
        phone: "",
        mensagens: [],
        vars: {},
        admin: false,
        fomartedContacts: [],
        hasContacts: false,
        uniqueWpp: "",
        contactfile: false,
        mode: "new",
        msgTitle: "",
        msgModel: false,
        columns: [],
        cols: [],
        rows: [],
        wpp: false,
        sended: [],
        columnsIndex: [],
        contacts: [],
        contactsReady: false,
        msgs: [],
        ready: false,
        whatsapp: {
            status: "loading",
            listing: false
        },
        config: {
            
        },
        numberColumn: {
            status: false,
            index: false
        }
    });


    return <AuthContext.Provider value={[authData, setAuthData]}>{children}</AuthContext.Provider>
}