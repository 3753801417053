import styled from "styled-components";

export const SRelatoryItem = styled.div`
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    .relatory-item-content {
        background-color: #e5e5e5;
        border-radius: 24px;
        padding: 12px;
        margin-bottom: 24px;
        h3 {
            font-weight: 900;
            font-size: 20px;
            margin-bottom: 12px;
            line-height: 1;
        }
        h3, p {
            color: #333;
            text-align: center;
        }
        p {
            font-size: 15px;
            font-weight: 600;
        }
        span {
            font-weight: 900;
        }
       
    }
    img {
        position: absolute;
        width: 48px;
        top: 0;
        left: 5px;
        z-index: 99;
    }
`;